@use "sass:color";
@use "./dimensions";
@use "./colors";
@use "@material/theme" with (
  $primary: #1492a7,
  $secondary: #0f2a66,
  $background: #45c3d8
);

@use "raincoat/css/_raincoat-base.scss";

@use "@material/banner";
@use "@material/banner/styles";
@use "@material/textfield/icon";
@use "@material/textfield/mdc-text-field";
@use "@material/checkbox/mdc-checkbox";
@use "@material/button/mdc-button";
@use "@material/data-table/data-table";
@use "@material/button";
@use "@material/icon-button";
@use "@material/icon-button/styles" as icon-button-styles;
@use "@material/menu-surface/mdc-menu-surface";
@use "@material/menu/mdc-menu";
@use "@material/list/mdc-list";
@use "@material/select/mdc-select";
@use "@material/select/styles" as selectStyles;
@use "@material/card";
@use "@material/top-app-bar/mdc-top-app-bar";
@use "@material/drawer";
@use "@material/dialog";
@use "@material/radio/styles" as radioStyles;
@use "@material/tooltip/styles" as tooltip-styles;
@use "@material/tab-bar/mdc-tab-bar";
@use "@material/tab-indicator/mdc-tab-indicator";
@use "@material/tab-scroller/mdc-tab-scroller";
@use "@material/tab/mdc-tab";

@include drawer.core-styles;
@include drawer.dismissible-core-styles;
@include drawer.modal-core-styles;
@include card.core-styles;
@include dialog.core-styles;
@include data-table.core-styles;
@include data-table.theme-baseline;

#body-content {
  max-width: 856px;
  margin: auto;
  padding: 2em;
  padding-bottom: 48px;
  position: relative;
  min-height: 80vh;
}

.hidden {
  display: none;
}

.pointer {
  cursor: pointer;
}

.display-offscreen {
  left: -1000px;
  position: absolute;
}

.mdc-top-app-bar {
  background-color: colors.$secondary;
  width: 100vw;

  .active-navbar-item {
    background-color: white;
    color: colors.$secondary;
  }

  .mdc-top-app-bar__section {
    a:hover {
      text-decoration: none;
    }

    .mdc-top-app-bar__title {
      color: white;
      font-weight: 700;
      font-size: 1.25rem;
      margin-right: 0.5rem;
    }

    button {
      border: 1px solid white;

      &:hover {
        border: 1px solid colors.$secondary;
        background-color: white;
        color: colors.$secondary;
      }
    }
  }
}

.mdc-drawer--open {
  padding-left: 0.5rem;

  a {
    color: colors.$secondary !important;
  }

  button {
    background-color: colors.$secondary;
    color: white !important;
  }

  .active-mobile-navbar-item {
    background-color: colors.$secondary;

    a {
      color: white !important;
    }
  }

  .mobile-logout {
    margin-top: 0.25rem;
  }
}

.mdc-touch-target-wrapper {
  a:hover {
    text-decoration: none;
  }
}

.mdc-top-app-bar__action-item:hover {
  text-decoration: none;
}

i.navbar-icon-spacing {
  margin-right: 0.25rem;
  padding-bottom: 0.25rem;
}

.mdc-banner__content {
  top: 0;
}

#timeout-warning {
  .mdc-touch-target-wrapper {
    .mdc-button {
      background-color: #ffc107;
      color: #212529;
    }
  }
}

.mdc-button.bg-secondary {
  background-color: #0f2a66 !important;

  &:hover {
    background-color: #0f2a66 !important;
  }
}

.mdc-button.large-mdc-button {
  height: fit-content;
  width: min-content;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.mdc-radio--touch {
  margin-top: -10px !important;
}

#admin-modal-form {
  .mdc-dialog__title {
    width: fit-content;
  }

  .mdc-form-field {
    align-items: center;

    label {
      margin-bottom: 0;
    }
  }

  #organization_timezone {
    margin-top: 1rem;
  }

  .mdc-radio + label {
    margin-bottom: 0.75rem !important;
  }
}

.mdc-data-table {
  width: 100%;
  margin-bottom: 1rem;

  .mdc-data-table__header-cell {
    font-weight: bold;
  }

  .mdc-data-table__content {
    .organization-participant-count {
      text-align: center;
    }

    .action-cell {
      width: 20%;
    }

    .organization-action-cell {
      display: flex;
      flex-wrap: wrap;

      .mdc-touch-target-wrapper {
        margin-right: 0.5rem;
      }
    }
  }
}

.rc-pagination {
  display: flex;
  justify-content: flex-start;
  align-items: center;

  nav li::after {
    content: none !important;
  }

  .rc-pagination-list {
    display: flex;
    list-style: none;
    padding-left: 0 !important;

    .rc-page-item {
      color: #717171;
      font-size: 20px;
      text-align: center;
      width: 48px;

      .fas {
        vertical-align: text-top;
      }
    }
  }
}

.mdc-tooltip-wrapper--rich {
  margin-left: 0.35rem;

  .tooltip-trigger {
    color: #fff;
    background-color: #6c757d;
  }

  .mdc-tooltip__surface {
    max-height: max-content;
  }
}

.todo-list-badge {
  border-radius: 10rem;
  display: inline-block;
  padding: 0.25em 0.6em;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  margin-right: 0.5rem;
  transition:
    color 0.15s ease-in-out,
    background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;
}

.mdc-tab-scroller__scroll-content {
  flex-wrap: wrap;
  width: 100%;
}

.linked-list-item {
  .mdc-list-item__primary-text {
    color: #007bff !important;
  }
}

@media (max-width: dimensions.$tablet-width-max) {
  .topnav {
    display: none !important;
  }
}

@media (min-width: dimensions.$desktop-width-min) {
  #mobilenav {
    display: none !important;
  }
}
